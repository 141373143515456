export default {
  general: {
    to: 'to',
    none: 'None'
  },
  navMenu: {
    movies: 'movies',
    manage: 'manage',
    logout: 'logout'
  },
  pages: {
    movies: 'Universal Inbox'
  },
  compositions: {
    searchPlaceholder: 'Search for Compositions',
    filter: 'filter',
    filterInfoTitle: 'Filter - Movies',
    sortBy: 'Sorted by',
    expandAll: 'Expand All',
    collapseAll: 'Collapse All',
    view: 'View',
    viewFields: {
      all: 'all',
      sample: 'sample'
    },
    sortFields: {
      urgency: 'Relevance',
      other: 'other'
    },
    dcpStatusTitle: 'DCP Status',
    kdmStatusTitle: 'KDM Status',
    dcpStatus: {
      downloaded: 'Downloaded',
      pending: 'Pending',
      cancelled: 'Cancelled',
      dispatched: 'Shipped',
      pending_cancellation: 'Pending Cancellation',
      queued: 'Queued',
      failed: 'Failed',
      error: 'Failed',
      succeeded: 'Succeeded',
      executing: 'Downloading...',
      scheduled: 'Scheduled for Download',
      downloading: 'Downloading...',
      expired: 'Failed',
      intransit: 'In Transit',
      outfordelivery: 'Out For Delivery',
      attemptfail: 'Delivery AttemptFail',
      delivered: 'Delivered',
      exception: 'Delivery Exception',
      unarchiving: 'Unarchiving',
      downloading_unarchiving: 'Downloading - Unarchiving',
      pending_fulfilment_unarchiving: 'Pending Fulfilment - Unarchiving',
      archived: 'Archived',
      downloading_archived: 'Downloading - Archived',
      pending_fulfilment_archived: 'Pending Fulfilment - Archived',
      stalled: 'Stalled',
      downloading_stalled: 'Downloading - Stalled',
      pending_fulfilment_stalled: 'Pending Fulfilment - Stalled',
      in_progress: 'In Progress',
      completed: 'Completed'
    },
    kdmStatus: {
      emailed: 'Emailed',
      generated: 'Available',
      failed: 'Failed',
      inprogress: 'In Progress',
      action_required: 'Action Required',
      success: 'Available',
      unavailable: 'Unavailable'
    },
    viewCreditOffset: 'Credits: %{value}',
    noCreditOffset: 'No Credit Offset',
    theatres: 'Theatres',
    screens: 'Screens',
    theatre: 'Theatre',
    screen: 'Screen',
    multipleValidities: 'Multiple Validities',
    downloadKDMs: 'Download KDMs',
    creditOffset: 'Credit Offset',
    kdmDetails: 'KDM Details',
    assetDetails: 'Asset Details',
    name: 'Composition Name',
    status: 'Status',
    downloadAll: 'Download All',
    validity: 'Validity',
    multipleEmails: 'Multiple Emails',
    download: 'Download',
    markers: {
      ffec: 'FFEC',
      ffmc: 'FFMC'
    },
    cplMarkers: 'CPL Markers',
    creditsOffset: 'Credits Offset',
    standByServer: 'Standby Server',
    no_compositions: 'No Compositions',
    code: {
      INB01: {
        no_group_assigned: 'You have currently not been assigned any theatres/groups on Qube Wire.',
        contact_admin:
          'Contact your company admin to start managing content & keys for your theatres!'
      }
    },
    attributes: {
      language: 'Language',
      subtitleLanguage: 'Subtitle Language',
      mainCaption: 'Main Caption',
      ratings: 'Ratings',
      type: 'Type',
      dciStandard: 'DCI Standard',
      closedSubtitles: 'Closed Subtitles',
      frameRate: 'Frame Rate',
      audioFormats: 'Audio Formats',
      resolution: 'Resolution',
      closedCaptions: 'Closed Captions',
      stereoType: 'Stereo Type',
      aspectRatio: 'Aspect Ratio'
    },
    kdmDeliveryEmail: 'KDM Delivery Email',
    emailKDMS: 'Email KDMs',
    enterEmailAddress: 'Enter Email Addresses',
    email: 'Email',
    sendKDMEmailError: {
      empty: 'Atleast one email is required',
      invalidEmail: 'Invalid Email',
      send: 'Error in sending KDM Email'
    },
    sendKDMEmailSuccess: "KDM Email's sent",
    kdm: 'KDM',
    dcp: 'DCP',
    dcpDownloadCompleted: 'Completed',
    trackingInfo: 'Tracking Info'
  },
  filterPanel: {
    theatres: {
      title: 'Theatres',
      type: 'Theatres',
      placeholder: 'Search for theatres',
      dropDownPlaceholder: 'Search for theatres'
    },
    kdmValidity: {
      title: 'KDM VALIDITY',
      type: 'KDM VALIDITY',
      kdmValidityOptions: {
        validNow: 'Valid Now',
        future: 'Valid in Future',
        expiring: 'Expiring (in %{days} days)',
        expired: 'Expired'
      }
    },
    compositionType: {
      title: 'COMPOSITION TYPE',
      type: 'COMPOSITION TYPE',
      compositionTypeOptions: {
        advertisement: 'Advertisement',
        feature: 'Feature/Features',
        trailerTeaserPromo: 'Trailer/Teaser/Promo',
        psaPolicy: 'PSA/Policy',
        others: 'Others'
      }
    },
    dcpDownloadStatus: {
      title: 'DCP DOWNLOAD STATUS',
      type: 'DCP DOWNLOAD STATUS',
      dcpDownloadStatusOptions: {
        pending: 'Pending',
        downloadingProgress: 'Downloading/Progress',
        paused: 'Paused',
        completedDelivered: 'Completed/Delivered',
        others: 'Others'
      }
    },
    dcpIngestStatus: {
      title: 'DCP INGEST STATUS',
      type: 'DCP INGEST STATUS',
      dcpIngestStatusOptions: {
        pending: 'Pending',
        downloadingProgress: 'Downloading/Progress',
        paused: 'Paused',
        completedDelivered: 'Completed/Delivered',
        others: 'Others'
      }
    },
    kdmStatus: {
      title: 'KDM STATUS',
      type: 'KDM STATUS',
      kdmStatusOptions: {
        pending: 'Pending',
        downloadingProgress: 'Downloading/Progress',
        paused: 'Paused',
        completedDelivered: 'Completed/Delivered',
        others: 'Others'
      }
    },
    kdmIngestStatus: {
      title: 'KDM INGEST STATUS',
      type: 'KDM INGEST STATUS',
      kdmIngestStatusOptions: {
        pending: 'Pending',
        downloadingProgress: 'Downloading/Progress',
        paused: 'Paused',
        completedDelivered: 'Completed/Delivered',
        others: 'Others'
      }
    }
  },
  filterLabel: {
    theatre: 'Theatre'
  },
  theatre: {
    screen: 'Screen',
    theatres: 'theatres',
    chains: 'chains',
    no_theatres: 'No Theatres available',
    theatre: 'Theatre',
    filterByTheatres: 'Filter by Theatres',
    searchPlaceholder: 'Search for theatres you manage'
  },
  errors: {
    fetchCompositions: 'Error in loading compositions',
    fetchTheatres: 'Error in loading theatres',
    fetchUser: 'Error in loading user information',
    downloadKDMs: 'Error in KDM download',
    NoDownloadKDMs: 'No KDMs to download',
    fetchCount: 'Error in loading total no of WireTAPs',
    applianceInfo: 'Error in loading WireTAPs',
    fetchAllUsers: 'Error in loading users list',
    createGroup: 'Error while creating group',
    fetchGroups: 'Error in loading groups list',
    fetchFilterSettings: 'Error while fetching filter settings',
    fetchDevices: 'Error while fetching devices',
    downloadReport: 'Error while downloading Report',
    fetching: 'Error while fetching data',
    noMatchFound: 'No match found'
  },
  success: {
    downloadReport: 'Download started!'
  },
  shipmentStatuses: {
    Pending: 'Pending',
    InfoReceived: 'In Transit',
    InTransit: 'In Transit',
    OutForDelivery: 'Out For Delivery',
    AttemptFail: 'Delivery Attempt Failed',
    Delivered: 'Delivered',
    Exception: 'Delivery Exception',
    Expired: 'Expired'
  },
  unarchival_tooltip: {
    unarchival_may_take: 'Unarchival may take',
    hour: 'Hour',
    minute: 'Minute',
    from_time_of_booking: 'from the time of booking.'
  },
  users: {
    headerTitle: 'Users',
    headerButtons: {
      editRoles: 'Edit Roles',
      inviteUsers: 'Invite Users',
      viewGroups: 'View Groups'
    }
  },
  groups: {
    groupsList: {
      headerTitle: 'Groups',
      headerButtons: {
        createGroup: 'Create Group'
      }
    },
    groupDetail: {
      headerButtons: {
        addUser: 'Add User',
        editGroup: 'Edit Group'
      }
    },
    createGroup: {
      headerTitle: 'Create Group',
      headerButtons: {
        cancel: 'Cancel',
        save: 'Save'
      },
      groupName: 'Group Name',
      groupNamePlaceholder: 'Enter Group Name',
      groupCreated: 'Group created'
    },
    editGroup: 'Edit group',
    groupUpdated: 'Group Updated'
  },
  device: {
    devices: 'Devices',
    suite: 'Suite',
    uploadCertificate: 'Upload Certificate',
    add: 'Add',
    update: 'Update',
    addDevice: 'Add Device',
    addSuite: 'Add Suite',
    standbyHeader: 'Standby Devices (%{count})',
    addStandbyDevice: 'Add Standby Device',
    addSuiteHeader: 'Add Suite - Screen %{screen} - Suite %{suite}',
    addDeviceHeader: 'Add Device - Screen %{screen} - Suite %{suite}',
    editDevice: 'Edit Device',
    standbyDevices: 'Standby Devices',
    uploadDeviceCertificate: 'Upload Device Certificate',
    edit: 'Edit',
    cancel: 'Cancel',
    moveToStandby: 'Move to Standby',
    remove: 'Remove',
    removeDevice: 'Remove Device',
    standByToolTip: 'Few devices are not mapped to suites.',
    deviceModel: 'Device Model',
    searchDeviceModel: 'Search Device Model',
    serialNumber: 'Serial Number',
    searchSerialNumber: 'Search Serial Number',
    deviceRole: 'Device Role',
    fetchingRoles: 'Fetching Roles...',
    selectRole: 'Select Role',
    selectDevice: 'Select Device',
    ipAddress: 'IP Address',
    enterIpAddress: 'Enter IP Address',
    subnetMask: 'Subnet Mask',
    enterSubnetMask: 'Enter Subnet Mask',
    gateway: 'Gateway',
    enterGateway: 'Enter Gateway',
    softwareVersion: 'Software Version',
    enterSoftwareVersion: 'Enter Software Version',
    moveModalTitle: 'Confirm Move',
    moveModalText: 'Are you sure want to move %{name} to Standby?',
    moveModalText2: "On moving the device to Standby Devices, it won't exist in %{screen}%{suite}.",
    removeModalTitle: 'Confirm Remove',
    removeModalText: 'Are you sure want to remove the %{name}?',
    removeModalText2:
      "On removing the device you won't be able to access it until you add it again.",
    certificateExpired: 'Certificate Expired',
    certificateNotAvailable: 'Device certificate unavailable for this serial number.',
    emptyScreen: 'No suite added to the screen',
    deviceRequired: 'Device Model field is required.',
    serialRequired: 'Serial Number field is required.',
    invalidIP: 'Please enter a valid IP address.',
    invalidSubnet: 'Please enter a valid Subnet Mask.',
    invalidGateway: 'Please enter a valid Gateway.',
    invalidSoftware: 'Please enter a valid Software Version.',
    lockCertificate: 'Lock Certificate',
    unlockCertificate: 'Unlock Certificate',
    exactly_1_SM_msg: "Every Suite must contain exactly 1 SM. Please create new suite to add 2nd SM",
    needs_1_SM_msg: "Every suite must contain exactly 1 SM. Please add SM device with certificate to proceed",
    same_serial_msg: "Device Serial already exists in Suite. Please remove to proceed",
    screenInactive: 'Inactive',
    warn_exactly_1_SM_msg: "Every Suite must contain exactly 1 SM"
  },
  reports: {
    headerTitle: 'Reports'
  }
}
